.DrawerToggleBtn-button {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 50px;
  height: 50px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-transition: all 0.7s ease;
  -moz-transition: all 0.7s ease;
  -ms-transition: all 0.7s ease;
  -o-transition: all 0.7s ease;
  transition: all 0.7s ease;
}

.DrawerToggleBtn-line {
  width: 2.5rem;
  height: 3px;
  margin-bottom: 0.5rem;
  background-color: #fff;
}

.DrawerToggleBtn-button:hover {
  transform: scale(1.5);
}

@media (min-width: 600px) {
  .DrawerToggleBtn-line {
    width: 3rem;
    margin-bottom: 0.75rem;
  }
}
