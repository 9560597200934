@import "../../constants.scss";

.ProjectCard-wrapper {
  overflow-y: auto;
  height: auto;
  background-color: #fff;
  color: $titleColor;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
  position: relative;
  z-index: 200;
  -webkit-transition: opacity 0.7s ease-in;
  -moz-transition: opacity 0.7s ease-in;
  -ms-transition: opacity 0.7s ease-in;
  -o-transition: opacity 0.7s ease-in;
  transition: opacity 0.7s ease-in;
  margin-left: auto;
  margin-right: auto;
  @include atSmall {
    max-width: $mobileWidth;
  }
  @include atMedium {
    max-width: 90vw;
  }
}

.ProjectCard-header {
  height: 5vh;
  background-color: $headerColor;
  box-shadow: 5px 4px 4px rgba(255, 86, 208, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0 1rem 2rem;
  @include atSmall {
    max-width: $mobileWidth;
  }
  @include atMedium {
    max-width: 100%;
  }
}

.ProjectCard-title {
  @include atSmall {
    font-size: 2rem;
  }
  @include atMedium {
    font-size: 3rem;
  }
}

.ProjectCard-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-family: "Comfortaa";
  color: #0c5dbf;
  list-style: none;
  @include atSmall {
    font-size: 1rem;
  }
  @include atMedium {
    font-size: 2rem;
  }
}

.ProjectCard-listItem {
  margin-top: 1rem;
  a {
    text-decoration: none;
    font-weight: bold;
    color: $highlightColor;
    &:hover {
      @include grow(1.05);
      color: $hoverColor;
    }
  }
}

.overview {
  text-align: left;
  padding-left: 1rem;
  padding-right: 1rem;
}

.ProjectCard-btn {
  padding-bottom: 1rem;
}

@include atMedium {
  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
