$backgroundColor: #fff;
$headerColor: #11dbca;
$titleColor: #0c5dbf;
$highlightColor: #ff56d0;
$hoverColor: #e3c147;

$pageHeight: 100vh;
$mobileWidth: 100vw;
$prefix: "-moz-", "-webkit-", "-o-", "-ms-", "";

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@mixin fade-in {
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
}

@mixin grow($scale) {
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale($scale);
  }
}

@mixin atSmall {
  @media (min-width: 230px) {
    @content;
  }
}

@mixin atMedium {
  @media (min-width: 800px) {
    @content;
  }
}
