@import "../../constants.scss";

.Footer__container {
  width: 100%;
  height: 30px;
  background-color: $headerColor;
  box-shadow: 5px 4px 4px rgba(255, 86, 208, 0.25);
  bottom: 0;
  left: 0;
  margin-top: 40px;
  padding: 30px 0 0 20px;
}

@media (min-width: 600px) {
  .Footer__container {
    height: 3vh;
  }
}
