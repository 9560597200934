@import "../../constants.scss";

.smallBtn__container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.smallBtn {
  text-decoration: none;
  background-color: #ff56d0;
  border: solid 2px #ff56d0;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  color: #fff;
  outline: none;
  box-shadow: 5px 4px 10px rgba(255, 86, 208, 0.15);
  transition: 0.3s;

  &:hover {
    background-color: #e3c147;
    border: solid 2px #e3c147;
    font-size: 0.8em;
    color: #fff;
    @include grow(1.1);
  }
}

@media (min-width: 600px) {
  .smallBtn__container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .smallBtn {
    width: 100px;
    height: 100px;
    border-radius: 50%;

    &:hover {
      font-size: 1em;
    }
  }
}
