@import "../../constants.scss";

.hand__container {
  display: inline-block;
  width: 80%;
}

@media (min-width: 600px) {
  .hand__container {
    width: 60%;
  }
}
