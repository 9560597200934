.circleBtn__container {
  width: 80%;
  display: flex;
  align-items: left;
  margin-left: 4em;
  margin-top: 0;
}

.circleBtn {
  text-decoration: none;
  background-color: #ff56d0;
  border: solid 2px#ff56d0;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  color: #fff;
  outline: none;
  font-size: 1em;
  box-shadow: 5px 4px 4px rgba(225, 70, 199, 0.3);
  transition: 0.3s;
}

.circleBtn:hover {
  background-color: #e3c147;
  border: solid 2px#E3C147;
  font-size: 1.5em;
  color: #fff;
}

@media (min-width: 600px) {
  .circleBtn__container {
    width: 60%;
    display: flex;
    align-items: left;
    justify-content: center;
  }

  .circleBtn {
    width: 150px;
    height: 150px;
    border-radius: 50%;
  }

  .circleBtn:hover {
    font-size: 2em;
  }
}
