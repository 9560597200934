.FeatureImg__container {
  width: 80%;
  display: inline-block;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.FeatureImg {
text-decoration: none;
background-color: #FF56D0;
width: 200px;
height: 200px;
border-radius: 50%;
color: #FF56D0;
box-shadow: 5px 4px 4px rgba(227, 193, 71, 0.3);
transition: 0.3s;
}

@media (min-width: 600px) {
  .FeatureImg__container{
    justify-content: center;
  }
    .FeatureImg {
  width: 400px;
  height: 400px;
  box-shadow: 5px 4px 10px rgba(227, 193, 71, 0.3);
  }
}