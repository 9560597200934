@import "../../constants.scss";

.Preview__main {
  @include atMedium {
    display: flex;
    justify-content: center;
  }
}

.Preview__img {
  box-shadow: 5px 4px 4px rgba(255, 86, 208, 0.25);
  @include fade-in;
  @include atSmall {
    max-width: 80%;
    height: auto;
  }
  @include atMedium {
    max-width: 45%;
    max-height: 45%;
  }
}
