@import "./constants.scss";

.App {
  height: $pageHeight;
  text-align: center;
}

.App-open {
  width: 80vw;
}

.title {
  @include fade-in;
}

@media (min-width: 700px) {
  .App-open {
    width: 90vw;
  }
}

@media (min-width: 1400px) {
  .DotBtn {
    padding-left: 10vw;
  }
}
