@import "./constants.scss";

.Portfolio {
  height: $pageHeight;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.Projects__title {
  margin-top: 2rem;
  @include fade-in;
}
