.title {
  display: inline-block;
  align-items: left;
  width: 100%;
  font-family: Comfortaa;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  color: #0c5dbf;
}

.firstname {
  margin-top: 1rem;
  margin-block-end: 0;
}

.pinkletter {
  color: #ff56d0;
}

.lastname {
  margin: 0;
}

.sub {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-family: Raleway;
  font-size: 0.75rem;
  color: #0c5dbf;
  margin: 0 0 2rem 0;
}

@media (min-width: 600px) {
  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.5rem;
  }

  .firstname {
    text-align: left;
    margin-block-end: 3vh;
  }

  .lastname {
    text-align: left;
    margin-top: 3vh;
    padding-left: 3vw;
  }

  .sub {
    font-size: 1.75rem;
  }
}
