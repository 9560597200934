@import "../../constants.scss";

.Header_container {
  width: $mobileWidth;
  background-color: $headerColor;
  box-shadow: 5px 4px 4px rgba(255, 86, 208, 0.25);
  display: inline-block;
  @include atSmall {
    height: 50px;
    padding: 2rem 0 1rem 2rem;
  }
  @include atMedium {
    .Header_container {
      height: 8vh;
      padding: 2rem 0 0.5rem 2rem;
    }
  }
}
