.SideDrawer-wrapper {
  width: 70%;
  max-width: 400px;
  height: 100%;
  background-color: #fff;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  -webkit-transition: opacity 0.7s ease-in;
  -moz-transition: opacity 0.7s ease-in;
  -ms-transition: opacity 0.7s ease-in;
  -o-transition: opacity 0.7s ease-in;
  transition: opacity 0.7s ease-in;
}

.SideDrawer-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  font-size: 1.75rem;
  font-family: "Comfortaa";
  color: #0c5dbf;
  list-style: none;
}

.SideDrawer-listItem {
  margin-top: 1rem;
}

.SideDrawer-listItem a {
  text-decoration: none;
  color: #0c5dbf;
}

.SideDrawer-listDot {
  color: #ff56d0;
  font-weight: 900;
}

.SideDrawer-listItem:hover,
.SideDrawer-listItem:active {
  color: #ff56d0;
  font-weight: 600;
}

@media (min-width: 600px) {
  .SideDrawer-list {
    font-size: 3rem;
  }
}
